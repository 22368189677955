import React from "react";

const HomeTTLDetails = ({ testiMonialDetail }) => {
  const { name, star, address, description, img } = testiMonialDetail;

  return (
    <div class="tesimonial-item">
      <div class="header-card d-flex">
        <div className="mt-1 ml-3">
          <p>{description}</p>
          <h3>{name}</h3>
        </div>
      </div>
    </div>
  );
};

export default HomeTTLDetails;
