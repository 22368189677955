import React from "react";
// import MyComponent from "../components/MyComponent";
import Marquee from "react-fast-marquee";

const BrandMarquee = () => (
  <Marquee>
    <div className="br-logo">
      <img src={require("./assets/brand/Biryani-bowl.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img
        src={require("./assets/brand/Bobby-G-Pizzaria.png")}
        alt="Brand Logo"
      />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/cafe-La-scala.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/El-Gran-Sabor.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img
        src={require("./assets/brand/Gusto-Pinsa-Romana.png")}
        alt="Brand Logo"
      />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/Peppers.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/Pop-Tea.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/RH48.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/RHCP.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img
        src={require("./assets/brand/square-pie-guys.png")}
        alt="Brand Logo"
      />
    </div>
    <div className="br-logo">
      <img
        src={require("./assets/brand/The-Italian-Homemade-Company.png")}
        alt="Brand Logo"
      />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/VKR1.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/VKR2.png")} alt="Brand Logo" />
    </div>
    <div className="br-logo">
      <img
        src={require("./assets/brand/VKR---Sunnyvale.png")}
        alt="Brand Logo"
      />
    </div>
    <div className="br-logo">
      <img src={require("./assets/brand/VKR---Texas.png")} alt="Brand Logo" />
    </div>
  </Marquee>
);

export default BrandMarquee;
