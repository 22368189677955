import React, { Component, Link } from "react";
import {
  Container,
  Navbar,
  NavItem,
  NavDropdown,
  MenuItem,
  Nav,
  Row,
  Col,
  Button,
  Card,
  Form,
} from "react-bootstrap";
// import simmerlogo from "../assets/newhome/Logo.png";
import phonelogo from "../assets/Phonedash1-rw-logo.png"
import logo from "../assets/logo.svg";
import "../Css/Simmer.css";
import "../Css/NewHome.css";
class Header extends Component {
  async componentDidMount() {
    let navbar = document.getElementById("nav");
    let navOffset = navbar.offsetTop;
    window.addEventListener("scroll", () => {
      window.scrollY >= 100
        ? navbar.classList.add("fix")
        : navbar.classList.remove("fix");
    });
  }

  goForm = () => {
    document.getElementById("dots").click();
  };

  render() {
    return (
      <>
        <div className="new-simmer-header">
          <Navbar
            expand="lg"
            id="nav"
            sticky="top"
            className="p-2"
            variant="dark"
          >
            <Container>
              <Navbar.Brand href="/">
                <img alt="Simmer" src={phonelogo} className="simmer-logo" />
              </Navbar.Brand>
              <Navbar.Toggle id="dots" aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  activeKey={window.location.pathname}
                  className="me-auto justify-content-end flex-grow-1"
                >
                  <NavDropdown
                    className="header-dropdown"
                    title="Solutions"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/ai-order-system">
                      AI Order System
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/solutions">
                      Digital Order System
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/about-us">About Us</Nav.Link>
                  <Nav.Link className="hidden" href="/solutions">
                    Solutions
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/pricing">
                    Pricing
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/learn">
                    Learn
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/thankyou">
                    Thankyou
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/blog">
                    Blog
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/ai-order-system">
                    AI order System
                  </Nav.Link>
                  <Nav.Link href="/contact">Contact Us</Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/restaurant-software-solution-in-sanjose"
                  >
                    Sanjose
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/restaurant-software-solution-in-sunnyvale"
                  >
                    Sunnyvale
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/restaurant-software-solution-in-san-francisco"
                  >
                    San Francisco
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/restaurant-software-solution-in-fremont"
                  >
                    Fremont
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/qr-code-ordering-system-in-sanjose"
                  >
                    QR Code Ordering System in San Jose
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/qr-code-ordering-system-in-sunnyvale"
                  >
                    QR Code Ordering System in Sunnyvale
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/qr-code-ordering-system-in-san-francisco"
                  >
                    QR Code Ordering System in San Francisco
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/qr-code-ordering-system-in-fremont"
                  >
                    QR Code Ordering System in Fremont
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/ramp-up-your-restaurant-online-ordering-system"
                  >
                    5 Ways To Ramp Up Your Restaurant Online Ordering System
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/benefits-of-owning-an-online-food-ordering-portal"
                  >
                    Benefits of Owning an Online Food Ordering Portal
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/simmer-all-in-one-restaurant-software-solution"
                  >
                    Phonedash: All-in-one Restaurant Software Solution
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/10-ways-simmer-helps-restaurant-owners"
                  >
                    10 Ways Phonedash Helps Restaurant Owners
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/how-can-restaurant-deal-with-staff-shortage-issues"
                  >
                    How Can A Restaurant Deal With Staff Shortage Issues
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/are-free-online-ordering-systems-restaurants-worth"
                  >
                    Are Free Online Ordering Systems For Restaurants Worth It?
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/12-reasons-to-use-a-qr-code-menu-restaurant"
                  >
                    12 reasons to use a QR code menu in a restaurant
                  </Nav.Link>
                  <Nav.Link
                    className="hidden"
                    href="/blog/understanding-qr-code-payments-types-benefits-and-trends"
                  >
                    Understanding QR Code Payments: Types, Benefits, and Trends
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/webstories">
                    Webstories
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/no-more-missed-orders">
                    No More Missed Orders
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/career">
                    Career
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/tutorials">
                    Tutorials
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/customers">
                    Customers
                  </Nav.Link>
                  <Nav.Link className="hidden" href="/res-signup">
                    Signup
                  </Nav.Link>
                  {/* <Nav.Link className="hidden" href="/newhome">
                    New Home
                  </Nav.Link> */}
                  <Nav.Link className="hidden" href="/request-a-demo">
                    Request A Demo
                  </Nav.Link>
                </Nav>
                <div className="header-btns ml-3 d-flex align-items-center py-3">
                  <div>
                    <a
                      href="/request-a-demo"
                      // onClick={this.goForm}
                      // id="booknow"
                      className="head-btn-one"
                    >
                      Book Now
                    </a>
                    {/* <Button id="booknow" className="rounded-pill primary" variant="primary" onClick={this.props.btnClick}>
                      Book
                    </Button> */}
                  </div>

                  <div>
                    <a
                      className="head-btn-two"
                      href="https://order.trysimmer.com/merchant"
                    >
                      Merchant Login
                    </a>
                  </div>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </>
    );
  }
}

export default Header;
