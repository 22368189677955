import React, { Component, createRef } from "react";
import {
  Container,
  ListGroup,
  Row,
  Col,
  Button,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import NewHeader from "./Header/Header";
import Footer from "./Footer/Footer";
import "./Css/Simmer.css";
import "./Css/NewHome.css";
import heroImgnew from "./assets/newhome/banner.png";
import benbg from "./assets/newhome/benefits.png";
import heroBanner from "./assets/hero-bg.webp";
import Scan from "./assets/scan-order.webp";
import Support from "./assets/live-support.webp";
import Pos from "./assets/pos-integration.webp";
import Virtual from "./assets/virtual-menu.webp";
import Person from "./assets/person.webp";
import Rname from "./assets/rname.webp";
import Time from "./assets/time.webp";
import Select from "./assets/select-arrow.webp";
import Email from "./assets/envelope.webp";
import HomeTestimonial from "./HomeTestimonial/HomeTestimonial";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@material-ui/core";
import moment from "moment";
import RequestConsultation from "./Forms/RequestConsultation";
import LogoTestiMonial from "./LogoTestimonial";
import BrandMarquee from "./BrandMarquee";

class NewHome extends Component {
  state = {
    name: "",
    email: "",
    restaurant_name: "",
    callback_time: "",
    customer_type: "I already own Phonedash",
    tags: ["website"],
    show: false,
    timeValue: "",
  };
  scrollDiv = createRef();
  scrollSmoothHandler = (selector, offset) => {
    this.scrollDiv.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  videoPlay = (e) => {
    e.preventDefault();
    var iframe = document.createElement("iframe");
    iframe.setAttribute(
      "src",
      "https://www.youtube.com/embed/ZPCDsUH8FQ8?rel=0&autoplay=1"
    );
    iframe.style.borderRadius = "20px";
    iframe.style.border = "0";
    iframe.style.width = "100%";
    iframe.style.height = "350px";
    iframe.setAttribute("allow", "autoplay");
    iframe.setAttribute("allowFullScreen", "allowFullScreen");
    document.getElementById("play").appendChild(iframe);
    document.getElementById("link").style.display = "none";
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.name != "" && this.state.email != "") {
      console.log(this.state);
      axios
        .post("https://back.agileads.us/forms", this.state, {
          headers: {
            Authorization: "Bearer 6764e6e0-c4f8-44a2-be49-811c48f2565d",
          },
        })
        .then((res) => {
          console.log(res.data);
          window.location.href = "/thankyou";
        });
    } else {
      document.getElementById("error").style.display = "block";
      setTimeout(() => {
        document.getElementById("error").style.display = "none";
      }, 3000);
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <>
        <React.Fragment>
          <div
            className="home-banner banner-title"
            style={{ backgroundImage: "url(" + heroImgnew + ")" }}
          >
            <NewHeader
              link={this.scrollDiv}
              btnClick={this.scrollSmoothHandler}
            />

            <Container className="banner-content">
              <div>
                <h1 style={{ fontSize: "2.7em" }}>
                  PhoneOrder Pro: Never miss a call, never miss an order
                </h1>
                <div className="d-flex justify-content-center bb-btn">
                  <a href="/request-a-demo" className="bg-btns">
                    Get Started
                  </a>
                </div>
              </div>
            </Container>
          </div>
          <div
            className="benefits"
            style={{ backgroundImage: "url(" + benbg + ")" }}
          >
            <div className="corner-img">
              <img
                src={require("./assets/newhome/corner-pizza.png")}
                alt="Pizza"
              />
            </div>
            <Container>
              <div className="ben-img">
                <img
                  src={require("./assets/newhome/ben-img.png")}
                  alt="Banner"
                />
              </div>
            </Container>
          </div>
          <div className="demo-section">
            <Container>
              <div className="pizza-img">
                <img src={require("./assets/newhome/pizza.png")} alt="Pizza" />
              </div>
              <Row>
                <Col lg={4} md={4} sm={12}></Col>
                <Col lg={8} md={8} sm={12}>
                  <div>
                    <h2 className="demo-head">The Easy Way to Order Pizza.</h2>
                    <div className="demo-img">
                      <div class="video-contents" id="play">
                        <a id="link" href="" onClick={this.videoPlay}>
                          <img
                            src={require("./assets/newhome/video.png")}
                            alt="Video"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="gd-btn">
                      <a href="/request-a-demo" className="demo-btn">
                        Get Demo
                      </a>
                    </div>
                    <p className="text-center">
                      Sign up for a free trial today and see how PhoneOrder Pro
                      can help you grow your business!
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="home-testi">
            <Container>
              <h2 className="ttl-head">Testimonials</h2>
            </Container>
          </div>
          <div className="ttl-content">
            <Container>
              <HomeTestimonial />
            </Container>
          </div>
          <div className="ttl-border">
            <div className="t-img">
              <img
                src={require("./assets/newhome/test-pizza.png")}
                alt="Sign Up"
              />
            </div>
          </div>
          <div className="pr-section">
            <Container>
              <div className="gt-row">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <div className="gt-img">
                      <img
                        src={require("./assets/newhome/guarantee.png")}
                        alt="Sign Up"
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <h3>Guarantee</h3>
                      <p>
                        <b>
                          We're so confident that you'll love PhoneOrder Pro
                          that we offer a 100% satisfaction guarantee.
                        </b>
                        If you're not happy with PhoneOrder Pro for any reason,
                        simply cancel your subscription and you'll get your
                        money back.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="c-logo">
                <Container>
                  <div className="chat-img">
                    <img
                      src={require("./assets/newhome/Logo-symbol.png")}
                      alt="Sign Up"
                    />
                  </div>
                </Container>
              </div>
              <div className="pr-row">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <h3>Pricing</h3>
                      <p>

                        <b>

                          PhoneOrder Pro is offered through a convenient monthly subscription.

                        </b> Also, enjoy exclusive discounts for multiple restaurant subscriptions and even greater savings with our annual subscription plans. <a className="link primary" href="/contact/#request">Contact us</a> to customize a subscription that fits your needs.

                      </p>


                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div className="pr-img">
                      <img
                        src={require("./assets/newhome/pricing.png")}
                        alt="Sign Up"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <div className="brand-section">
            <Container>
              <div className="brands-marquee">
                <BrandMarquee />
              </div>
            </Container>
          </div>
          <div className="get-section py-3">
            <Container>
              <div className="text-center">
                <a href="/request-a-demo" className="gt-btn mb-4">
                  Get Started Today
                </a>
                <p>
                  Sign up today and see how PhoneOrder Pro can help you grow
                  your business!
                </p>
              </div>
            </Container>
          </div>
          <Footer />
        </React.Fragment>
      </>
    );
  }
}

export default NewHome;
