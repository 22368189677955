import React, { useEffect, useState } from "react";
import HomeTTLDetails from "../HomeTestimonial/HomeTTLDetails";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Css/NewHome.css";

const HomeTestimonial = () => {
  const testiMonials = [
    {
      description:
        "“ PhoneOrder Pro has been a lifesaver for our restaurant. We're able to take orders 24/7, and our customers love the convenience of being able to order online or through live chat. PhoneOrder Pro has also helped us improve our customer service by giving us a better understanding of our customers' needs. ”",
      name: "- j.penrod, Road House 48",
    },
    {
      description:
        "“ PhoneOrder Pro is the easiest way to take orders for our restaurant. It's simple to use, and it integrates seamlessly with our POS system. We've been able to increase our sales by 20% since we started using PhoneOrder Pro. ”",
      name: "- Jagan, Bobby G's Pizzeria",
    },
    {
      description:
        "“ Phonedash is great because it allows my team to focus on the task at hand while still providing great customer service to folks who call my business ”",
      name: "- Square pie guys testimonial ",
    },
  ];
  //Owl Carousel Settings
  const options = {
    loop: true,
    center: false,
    margin: 0,
    items: 2,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      767: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1025: {
        items: 1,
      },
    },
  };
  return (
    <section id="testimonial" className="hometestimonial pt-70 pb-70">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel
              id="customer-testimonoals"
              className="owl-carousel owl-theme"
              {...options}
            >
              {testiMonials.length === 0 ? (
                <div class="item">No Reviews</div>
              ) : (
                testiMonials.map((testiMonialDetail) => {
                  return (
                    <HomeTTLDetails
                      testiMonialDetail={testiMonialDetail}
                      key={testiMonialDetail._key}
                    />
                  );
                })
              )}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTestimonial;
