import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import logo1 from './assets/logos/Biryani-bowl.png'
import logo2 from './assets/logos/Bobby-G-Pizzaria.png'
import logo3 from './assets/logos/cafe-La-scala.png'
import logo4 from './assets/logos/El-Gran-Sabor.png'
import logo5 from './assets/logos/Gusto-Pinsa-Romana.png'
import logo6 from './assets/logos/Peppers.png'
import logo7 from './assets/logos/Pop-Tea.png'
import logo8 from './assets/logos/RH48.png'
import logo9 from './assets/logos/RHCP.png'
import logo10 from './assets/logos/square-pie-guys.png'
import logo11 from './assets/logos/The-Italian-Homemade-Company.png'
import logo12 from './assets/logos/VKR1.png'
import logo13 from './assets/logos/VKR2.png'
import logo14 from './assets/logos/VKR---Sunnyvale.png'
import logo15 from './assets/logos/VKR---Texas.png'

 
const LogoTestiMonial = () => {  
    const testiMonials = [
        {
            
            img: logo1
        },
        {
            
            img: logo2
        },
        {
            
            img: logo3
        },
        {
            
            img: logo4
        },
        {
            
            img: logo5
        },
        {
            
            img: logo6
        },
        {
            
            img: logo7
        },
        {
            
            img: logo8
        },
        {
            
            img: logo9
        },
        {
            
            img: logo10
        },
        {
            
            img: logo11
        },
        {
            
            img: logo12
        },
        {
            
            img: logo13
        },
        {
            
            img: logo14
        },
        {
            
            img: logo15
        },
       
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,        
        margin: 5,
        items: 10,
        autoplay: true,
        dots: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            767: {
                items: 1,
                center: false,        
        margin: 0,
               
            },
            768 : {
                items : 2
            },
            1025:{
                items: 5
            }
           
        }
    };
    return (
        <section id="testimonial-logo" className="logo-testimonial pb-5">
            <div className="container mt-5">
                <div className='titles'><h2 style={{marginBottom: '1em'}}>See Our <span>Partners</span></h2></div>
                <div className="row">
                    <div className="col-md-12">
                        <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div class="item">
                                       No Clients
                                    </div> :
                                    testiMonials.map(logos => {
                                        return (
                                            <div key={logos} className='testimonial-logo'>
                                                <img src={logos.img} alt="Logos"/>
                                            </div>

                                        )
                                    })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LogoTestiMonial;