import React, { Component, createRef } from "react";
import {
  Container,
  ListGroup, 
  Row,
  Col,
  Button,
  Card,Form, InputGroup
} from "react-bootstrap";
import "../Css/Simmer.css";
import Person from "../assets/form-icons.svg";
import Rname from "../assets/form-icons-1.svg";
import Time from "../assets/form-icons-2.svg";
import Select from "../assets/select-arrow.webp";
import Email from "../assets/form-icons-3.svg";
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@material-ui/core';
import moment from "moment"; 
class RequestConsultation extends Component { 
    state = {
        name: "",
        email: "",
        restaurant_name: "",
        callback_time: "",
        customer_type: "I already own Phonedash",
        tags: ["simmer"],
        show: false,
        timeValue: ""
      };
      /* scrollDiv = createRef();
      scrollSmoothHandler = (selector, offset) => {
        this.scrollDiv.current.scrollIntoView({ block: 'start', behavior: "smooth" });
      }; */
         
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.name != "" && this.state.email != "") {
          console.log(this.state);
          axios
            .post(
              "https://back.agileads.us/forms",
              this.state,
              {
                headers: {
                  Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IlNuZTRXWHpOZ1hTRHBIRmtvRWRzIiwiY29tcGFueV9pZCI6IlNtNjdhNkEyNkR5S21mVUtTbTJ4IiwidmVyc2lvbiI6MSwiaWF0IjoxNjY3MTE2ODAwMjAwLCJzdWIiOiJ1c2VyX2lkIn0.7atLAZ60ygzrNR1F-UxjwKZ7g-NAN8P6iuXxGGuOSOo",
                },
              }
            )
            .then((res) => {
              console.log(res.data);
              window.location.href = "/thankyou";
            });
        } else {
          document.getElementById("error").style.display = "block";
          setTimeout(() => {
            document.getElementById("error").style.display = "none";
          }, 3000);
        }
};
  handleChange = (e) => {   
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <>
        <React.Fragment>
          
          <div id="request">
          <Container>
          <div className="request-consultation">           
              <Row className="justify-content-center align-items-center">
              <Col lg={6} md={6} sm={12}>
                  <img style={{maxWidth: '500px', width:'100%'}} alt="Consultation" src={require("../assets/consultation.png")} />
                </Col>
                <Col style={{ padding: '0'}} className="pr-5 form-section" lg={6} md={6} sm={12}>
                  <h2>
                  Request a Consultation
                  </h2>
                  <img style={{width: 'auto'}}
                  className="mt-3 mb-4"
                    src={require("../assets/border.webp")}
                    alt="Border Line"
                  />
                <form onSubmit={this.handleSubmit}>
                <div id="error">Please fill all required details</div>
                <label>
          Select from the following option <br/>
          <select value={this.state.customer_type} style={{ backgroundImage: "url(" + Select + ")" }} id="customer_type" name="customer_type" onChange={this.handleChange}>
            <option value="I already own Simmer">I already own Phonedash</option>
            <option value="Other">Other</option>           
          </select>
        </label>
        <div className="d-flex">
        <label>
          Full Name
          <input name="name" style={{ color: 'red !important', backgroundImage: "url(" + Person + ")" }} type="text" id="name" placeholder="Full Name" value={this.state.name} onChange={this.handleChange} />
        </label>
        <label>
          Restaurant Name
          <input name="restaurant_name" style={{ backgroundImage: "url(" + Rname + ")" }} type="text" id="restaurant_name" placeholder="XYZ Restaurant" value={this.state.restaurant_name} onChange={this.handleChange} />
        </label>
        </div>
        <div className="d-flex">
        {/* <label>
        Callback Time
          <input type="time" name="callback_time" style={{ height: '42px', backgroundImage: "url(" + Time + ")" }} id="callback_time" placeholder="01:00 Hour" value={this.state.callback_time} onChange={this.handleChange} />
        </label>  */}    
        <label>
        <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker 
        style={{ height: '42px'}}
        renderInput={(props) => <TextField {...props} />}
        label="Callback time"     
        className="date-time"
        name="callback_time"        
        value={this.state.callback_time ? this.state.callback_time : new Date()}
        onChange={(e, i)=> {          
          const str = e.$d
          const dateStr = new Date(str).toISOString();          
          this.setState({
            callback_time: moment(dateStr).format("YYYY-MM-DD hh:mm a")
          })
        }
         }
      />
    </LocalizationProvider>
        </div>
        </label>

                <label>
        Email
          <input name="email" type="email" style={{ backgroundImage: "url(" + Email + ")" }} id="email"  placeholder="XYZ12@email.com" value={this.state.email} onChange={this.handleChange} />
        </label>
        </div>
      
        <div className="submit-btn"><input type="submit" value="Request a Consultation" /><a href="" onClick={this.handleSubmit} ><img src={require('../assets/submit-arrow.svg')} alt="Right Arrow"/></a></div>
      </form>
                </Col>
               
              </Row>
             
            </div>
            </Container>
            </div>
                 
         
        </React.Fragment>
      </>
    );
  }
}

export default RequestConsultation;

