import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import logo from "../assets/logo.svg";
import phonelogo from "../assets/Phonedash1-rb-logo.png"
import "../Css/Simmer.css"; 
class Footer extends Component {
  render() {
    return (
      <>
        <div className="home-footer">
          <Container>
           
            <Row className="d-flex justify-content-between c-footer">
             
              <Col lg={2} md={2} sm={12}>
                <div><img  height={50} src={phonelogo} alt="logo"/></div>
              </Col>
              <Col className="text-right" lg={10} md={10} sm={12}>
              <div className="social-icons">
                  <a
                    href="https://www.facebook.com/trysimmer"
                    target="_blank"
                  >
                   <img src={require("../assets/fb.svg")} alt="Facebook"/>
                  </a>
                  <a href="https://twitter.com/Try_Simmer" target="_blank">
                  <img src={require("../assets/twit.svg")} alt="Twitter"/>
                  </a>
                  <a
                    href="https://www.instagram.com/trysimmer/"
                    target="_blank"
                  >
                    <img src={require("../assets/insta.svg")} alt="Instagram"/>
                  </a>
                  <a href="https://www.youtube.com/@trysimmer">
                  <i style={{fontSize: '30px', verticalAlign: 'middle'}} class="fa fa-youtube-play"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/trysimmer/"
                    target="_blank"
                  >
                    <img src={require("../assets/link.svg")} alt="Linkedin"/>
                  </a>
                </div>
                <div className="d-flex justify-content-end">
                
                  <a href="/privacy" className="ptr-link">
                    Privacy Policy
                  </a>
               
                  <a href="/terms-conditions" className="ptr-link">
                    Terms and Conditions
                  </a>
                </div>
              </Col>
             
            </Row>
            <div className="border-top= text-center copyright border-top">
                  © Copyright 2023, Phonedash, Inc. - All Rights Reserved
                </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Footer;
